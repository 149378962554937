
import YextSettingsCard from '@/components/yext/YextSettingsCard.vue';
import { ProjectViewMixin } from '@/mixins/ProjectViewMixin';
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';

@Component({
  name: 'project-datasource-yext',
  components: { YextSettingsCard },
})
export default class ProjectDatasourceYext extends mixins(ProjectViewMixin) {}
