
import { GENERIC_ERROR_DESCRIPTION } from '@/constants';
import { BotMixin } from '@/mixins/BotMixin';
import { SelectOption } from '@/types';
import isEqual from 'fast-deep-equal/es6';
import { YextEntityData } from 'ignite360-core';
import { mixins } from 'vue-class-component';
import { Component, Watch } from 'vue-property-decorator';

@Component({
  name: 'yext-settings-card',
})
export default class YextSettingsCard extends mixins(BotMixin) {
  isLoading = true;

  isSubmitting = false;

  yextSettings = this.resetValue();

  get settingsHaveChanged(): boolean {
    return !isEqual(this.yextSettings, this.resetValue());
  }

  async created() {
    this.isLoading = true;
    try {
      await this.$store.dispatch('yext/fetchAllEntities');
    } catch (e) {
      this.$notify.error({
        title: 'Loading Yext-entities failed',
        description: GENERIC_ERROR_DESCRIPTION,
      });
    } finally {
      this.isLoading = false;
    }
  }

  get entities(): YextEntityData[] {
    return this.$store.state.yext.entities;
  }

  get sortedEntities(): YextEntityData[] {
    return this.entities.slice().sort((a, b) => a.name.localeCompare(b.name));
  }

  get selectOptions(): SelectOption[] {
    return this.sortedEntities.map((entity) => ({
      value: entity.meta.id,
      label: entity.name,
      disabled: false,
    }));
  }

  async onSubmit() {
    this.isSubmitting = true;
    try {
      await this.$updateBot({
        id: this.bot.id,
        data: {
          entityId: this.yextSettings.entityId,
        },
      });
      this.$notify.success('Successfully updated Yext-settings');
    } catch (e) {
      this.$notify.error({
        title: 'Updating Yext-settings failed',
        description: GENERIC_ERROR_DESCRIPTION,
      });
    } finally {
      this.isSubmitting = false;
    }
  }

  @Watch('bot', { immediate: true, deep: true })
  private reset() {
    this.yextSettings = this.resetValue();
  }

  private resetValue() {
    return {
      entityId: this.bot?.entityId || '',
    };
  }
}
